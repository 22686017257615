<template>
  <div class="box">
    <div v-show="loading">
      <div
        class="turntableBox"
        v-bg="{
          remoteSrc: imgUrl + imgList.backgroundImg,
          localSrc: require('../../assets/home-bg.png'),
        }"
      >
        <!-- 红包 -->
        <div class="envelopes">
          <img
            v-bg="{
              remoteSrc: imgUrl + imgList.prizePlateConfig.outerImg,
              localSrc: require('@/assets/redBg.png'),
            }"
            alt=""
          />
          <div class="openBut" @click="startrotate()">
            <img
              v-bg="{
                remoteSrc: imgUrl + imgList.pointImg,
                localSrc: require('@/assets/openBut.png'),
              }"
              alt=""
            />
          </div>
        </div>
        <!-- 问题咨询 -->
        <div class="consulting">
          <div class="question" @click="questionHref()">
            <img src="../../assets/wtzx.png" alt="" />
          </div>
          <div class="question" @click="agreement()">
            <img src="../../assets/yssm.png" alt="" />
          </div>
        </div>

        <!-- 中奖名单 -->
        <div class="winning">
          <img
            v-bg="{
              remoteSrc: imgUrl + imgList.prizeFormImg,
              localSrc: require('@/assets/winners.png'),
            }"
            alt=""
          />
          <div class="winningBox">
            <vue-seamless-scroll
              class="seamless-warp"
              :data="winningList"
              :class-option="classOption"
            >
              <ul class="winningList">
                <li v-for="(item, index) in winningList" :key="index">
                  <span>{{ item.nickName }}</span>
                  <span>{{ item.prizeName }}</span>
                  <span>{{ item.createTime }}</span>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
        <!-- 活动规则 -->
        <div class="rule">
          <img
            v-bg="{
              remoteSrc: imgUrl + imgList.rulesImg,
              localSrc: require('@/assets/rule-content.png'),
            }"
            alt=""
          />
        </div>

        <div class="bottomLogo">
          <img src="../../assets/redEnvelopes/bottom.png" alt="" />
        </div>
      </div>
      <assistant @click="showFather"></assistant>
      <winningRecord
        :recordShow="recordShow"
        :recordList="recordList"
        @click="showFather"
      ></winningRecord>
    </div>
  </div>
</template>

<script>
import { getImgUrl } from "../../api/index.js";
import { getDrawRecord } from "../../api/index.js";
import { wxshareNO } from "../../utils/date.js";
import { drawRecord } from "../../api/index.js";
import { journal, getWinningRecord } from "../../api/index.js";
import assistant from "../../components/assistant/index";
import winningRecord from "../../components/winningRecord/index";
import { areaList } from "@vant/area-data";
const wx = require("weixin-js-sdk");
export default {
  name: "authorize",
  data() {
    return {
      recordShow: false, //展示小助手
      openState: true,
      isBlacklist: false,
      loading: true,
      winningList: [],
      prizeList: [],
      recordList: {
        prizeLogLists: [],
        wealLogLists: [],
        awardsLogLists: [],
      },
      time: 3000, // 旋转时间
      rotNum: 3, // 旋转圈数基数
      imgUrl: process.env.VUE_APP_BASE_imgUrl,
      imgList: {
        pointImg: "",
        prizeFormImg: "",
        rulesImg: "",
        backgroundImg: "",
        prizePlateConfig: {
          outerImg: "",
          innnerImg: "",
        },
      },
      base: "",
      tipsShow: false,
      shopInputShow: false,
      isBinding: false,
      showArea: false,
      shop: {
        shopName: "",
        shopPhone: "",
        shopDetailed: "",
        shopAddress: "",
        province: "",
        city: "",
        area: "",
      },
      areaList: areaList,
      ruleText: "", //活动规则文案
    };
  },
  components: {
    assistant: assistant,
    winningRecord: winningRecord,
  },
  created() {
    // 监听通过链接跳转后(ios 和 android)返回的
    window.addEventListener(
      "pageshow",
      function (e) {
        if (
          e.persisted ||
          (window.performance && window.performance.navigation.type == 2)
        ) {
          if (e.persisted) {
            window.location.reload();
          }
        }
      },
      false
    );

    // 监听vue router history返回
    window.addEventListener(
      "popstate",
      function () {
        window.location.reload();
      },
      false
    );

    this.$nextTick(() => {
      this.getWinning();
      this.recordShow = false; //关闭小助手
    });
  },

  beforeRouteEnter(to, from, next) {
    next();
  },

  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.winningList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        base: "22.5",
        rotate: 0,
        openTouch: false,
      };
    },
  },
  methods: {
    autoRotate(arr) {
      if (arr.length) {
        let len = arr.length;
        let base = 360 / len;
        this.base = 360 / len;
        arr.forEach((item, index) => {
          // 指的是某个奖品区域的中间 : base/2
          item.angle = 360 - (base / 2 + index * base);
          this.$set(arr, index, item);
        });
      }
      return arr;
    },
    //开始旋转 angle角度  complete回调成功函数
    startrotate() {
      var that = this;

      if (this.openState) {
        this.openState = false;
        drawRecord().then(
          (res) => {
            this.openState = true;
            if (res.data.isTrue == 1 || res.data.isTrue == 0) {
              window.location.href = res.data.skipUrl;
            } else if (res.data.isTrue == 3) {
              this.$router.push({
                path: "/redEnvelopes",
                query: {
                  prizeId: res.data.prizeId,
                  drawLogId: res.data.drawLogId,
                },
              });
            }
          },
          function () {
            that.openState = true;
          }
        );
      }
      // 点击转盘埋点
      var maidian = {
        userVisit: "3",
      };
      journal(maidian);
    },
    showFather() {
      this.recordShow = !this.recordShow;
      if (this.recordShow) {
        document.body.style.overflow = "hidden";
        getWinningRecord().then((res) => {
          this.recordList = res.data;
        });
        var maidian = {
          userVisit: "8",
        };
        journal(maidian);
      } else {
        document.body.style.overflow = ""; //出现滚动条
      }
    },
    getImg() {
      getImgUrl({}).then((res) => {
        this.prizeList = res.data.prizePlateConfig.prizePlatConfig;
        this.imgList.outerImg = res.data.prizePlateConfig.outerImg;
        this.imgList = res.data;
      });
    },
    getWinning() {
      getDrawRecord().then((res) => {
        this.winningList = res.data;
      });
    },
    questionHref() {
      var openId = sessionStorage.getItem("openId");
      var projectCode = sessionStorage.getItem("projectCode");
      window.location.href =
        process.env.VUE_APP_Question_API +
        "/questionPhase/questionPhase.html?channelId=" +
        projectCode +
        "&titleName=400032&openId=" +
        openId;
    },
    agreement() {
      window.location.href =
        process.env.VUE_APP_Question_API + "/questionPhase/adult.html";
    },
    goBack() {
      this.rotate = 0;
    },
    closeShop() {
      this.shopInputShow = false;
    },
  },
  mounted: function () {
    wxshareNO();
    var that = this;
    history.pushState(null, null, document.URL);
    window.addEventListener(
      "popstate",
      function (e) {
        history.pushState(null, null, document.URL);
      },
      false
    );
    var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/;
    if (browserRule.test(navigator.userAgent)) {
      window.onpageshow = function (event) {
        if (event.persisted) {
          that.goBack();
          wxshareNO();
        }
      };
    }
    this.$nextRequest(this.getImg);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.box {
  position: relative;
  background: #fdcb00;

  .turntableBox {
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 500px;
    padding-bottom: 128px;

    img {
      display: block;
      width: 100%;
    }

    .envelopes {
      position: relative;
      width: 100%;
    }

    .consulting {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 100px;
      box-sizing: border-box;

      .question {
        width: 140px;
        margin: 20px auto 0;
        margin-bottom: 10px;
      }
    }

    .winning {
      position: relative;
      width: 540px;
      margin: 0 auto;

      margin-bottom: 60px;

      .winningBox {
        position: absolute;
        width: 420px;
        height: 160px;
        left: 60px;
        top: 150px;
        overflow: hidden;

        .winningList {
          li {
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            line-height: 44px;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;

              &:nth-child(2) {
                width: 180px;
              }

              &:last-child {
                text-align: right;
              }

              &:last-child {
                width: 120px;
                flex-shrink: 0;
              }

              &:first-child {
                width: 90px;
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }

    .rule {
      width: 640px;
      height: 678px;
      margin: 0 auto;
      border-radius: 10px;
      background: rgba(169, 99, 0, 0.14);
      overflow: auto;
      max-height: 648px;
      img {
        width: 576px;
        padding: 30px;
        margin-bottom: 40px;
      }
    }
  }
}

.turntableBox .outer {
  position: relative;
  width: 658px;
  margin: 0 auto;
  overflow: hidden;
}

.inner {
  width: 598px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.pointer {
  width: 188px;
  position: absolute;
  left: 235px;
  top: 190px;
  z-index: 2;
}

.openBut {
  position: absolute;
  top: 400px;
  width: 138px;
  left: 306px;
  animation: move 0.8s 0s infinite;
  -webkit-animation: move 0.8s 0s infinite;
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
}

@keyframes move {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.envelopes img,
.openBut img,
.bottomLogo img {
  display: block;
  width: 100%;
}

.bottomLogo {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 447px;
}
</style>
