var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},[_c('div',{directives:[{name:"bg",rawName:"v-bg",value:({
        remoteSrc: _vm.imgUrl + _vm.imgList.backgroundImg,
        localSrc: require('../../assets/home-bg.png'),
      }),expression:"{\n        remoteSrc: imgUrl + imgList.backgroundImg,\n        localSrc: require('../../assets/home-bg.png'),\n      }"}],staticClass:"turntableBox"},[_c('div',{staticClass:"envelopes"},[_c('img',{directives:[{name:"bg",rawName:"v-bg",value:({
            remoteSrc: _vm.imgUrl + _vm.imgList.prizePlateConfig.outerImg,
            localSrc: require('@/assets/redBg.png'),
          }),expression:"{\n            remoteSrc: imgUrl + imgList.prizePlateConfig.outerImg,\n            localSrc: require('@/assets/redBg.png'),\n          }"}],attrs:{"alt":""}}),_c('div',{staticClass:"openBut",on:{"click":function($event){return _vm.startrotate()}}},[_c('img',{directives:[{name:"bg",rawName:"v-bg",value:({
              remoteSrc: _vm.imgUrl + _vm.imgList.pointImg,
              localSrc: require('@/assets/openBut.png'),
            }),expression:"{\n              remoteSrc: imgUrl + imgList.pointImg,\n              localSrc: require('@/assets/openBut.png'),\n            }"}],attrs:{"alt":""}})])]),_c('div',{staticClass:"consulting"},[_c('div',{staticClass:"question",on:{"click":function($event){return _vm.questionHref()}}},[_c('img',{attrs:{"src":require("../../assets/wtzx.png"),"alt":""}})]),_c('div',{staticClass:"question",on:{"click":function($event){return _vm.agreement()}}},[_c('img',{attrs:{"src":require("../../assets/yssm.png"),"alt":""}})])]),_c('div',{staticClass:"winning"},[_c('img',{directives:[{name:"bg",rawName:"v-bg",value:({
            remoteSrc: _vm.imgUrl + _vm.imgList.prizeFormImg,
            localSrc: require('@/assets/winners.png'),
          }),expression:"{\n            remoteSrc: imgUrl + imgList.prizeFormImg,\n            localSrc: require('@/assets/winners.png'),\n          }"}],attrs:{"alt":""}}),_c('div',{staticClass:"winningBox"},[_c('vue-seamless-scroll',{staticClass:"seamless-warp",attrs:{"data":_vm.winningList,"class-option":_vm.classOption}},[_c('ul',{staticClass:"winningList"},_vm._l((_vm.winningList),function(item,index){return _c('li',{key:index},[_c('span',[_vm._v(_vm._s(item.nickName))]),_c('span',[_vm._v(_vm._s(item.prizeName))]),_c('span',[_vm._v(_vm._s(item.createTime))])])}),0)])],1)]),_c('div',{staticClass:"rule"},[_c('img',{directives:[{name:"bg",rawName:"v-bg",value:({
            remoteSrc: _vm.imgUrl + _vm.imgList.rulesImg,
            localSrc: require('@/assets/rule-content.png'),
          }),expression:"{\n            remoteSrc: imgUrl + imgList.rulesImg,\n            localSrc: require('@/assets/rule-content.png'),\n          }"}],attrs:{"alt":""}})]),_vm._m(0)]),_c('assistant',{on:{"click":_vm.showFather}}),_c('winningRecord',{attrs:{"recordShow":_vm.recordShow,"recordList":_vm.recordList},on:{"click":_vm.showFather}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottomLogo"},[_c('img',{attrs:{"src":require("../../assets/redEnvelopes/bottom.png"),"alt":""}})])}]

export { render, staticRenderFns }